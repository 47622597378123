import React from "react"
import "../styles/contact.css"
import Heading from "./heading.js"
import RedButton from "./redbutton.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
    faFacebookSquare, faTwitter, faInstagram, faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

function Contact () {
    return (
        <section className="contacts">
            <span id = "contact"></span>
            <div className="section-header">
                <Heading title = "CONTACT US" size = "h1" />
            </div>
            
            <div className="contact-wrapper">
                <div className="direct-contact-container">
                    <div className= "not-social">
                        <div className="direct-link">
                            <ul class="contact-list">
                                <li class="list-item">
                                    <span class="contact-text place">
                                        <FontAwesomeIcon icon = { faMapMarkerAlt } size = "1x" />
                                        <a href="https://g.page/liquid-rocketry-lab?share" target = "_blank" rel="noopener noreferrer" title="LRL Location">Raleigh | NC</a>
                                    </span>
                                </li>
                                
                                <li className="list-item">
                                    <span class="contact-text phone">
                                        <FontAwesomeIcon icon = { faPhoneAlt } size = "1x" />
                                        <a href="tel:1-919-896-3201" title="LRL phone contact">+1 (919) 896-3201</a>
                                    </span></li>
                                
                                <li className="list-item">
                                    <span className="contact-text gmail">
                                        <FontAwesomeIcon icon = { faEnvelope } size = "1x" />
                                        <a href="mailto:admin@liquidrocketry.com" title="LRL email">admin@liquidrocketry.com</a>
                                    </span></li>
                            </ul>
                        </div>
                        <div className="contact-buttons">
                            <br />
                            <RedButton link ="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=GL3SSSK45D4X2&source=url" text = " Donate" />
                        </div>
                    </div>

                    <hr/>
                    <ul className="social-media-list">
                        <a href="https://www.instagram.com/liquidrocketry/" target= "_blank" rel="noopener noreferrer" >
                            <li>
                                    <FontAwesomeIcon icon = { faInstagram } size = "2x" />
                            
                            </li>
                        </a>
                        {/*<a href="https://www.facebook.com/liquidrocketry/" target= "_blank" rel="noopener noreferrer" >
                            <li>
                                    <FontAwesomeIcon icon = { faFacebookSquare } size = "2x" />
                                
                            </li>
                        </a>*/}
                        {/*<a href="https://twitter.com/liquidrocketry" target= "_blank" rel="noopener noreferrer" >
                        <li>
                                <FontAwesomeIcon icon = { faTwitter } size = "2x" />
                            
                        </li>
                        </a> */}
                        <a href="https://www.linkedin.com/company/liquidrocketry" target= "_blank" rel="noopener noreferrer">
                            <li>
                                <FontAwesomeIcon icon = { faLinkedin } size = "2x" />
                            </li>
                        </a>
                    </ul>
                    <hr/>
                </div>            
            </div>
            
            </section>  
  
  
    )
}

export default Contact
