import React from "react"
import Heading from "./heading.js"
import RedButton from "./redbutton.js"
import "../styles/leadership.css"
import John from "../images/leadership/john.png"
import Rodrigo from "../images/leadership/rodrigoheadshot.jpg"
import Alex from "../images/leadership/alexheadshot.jpg"
import Becky from "../images/leadership/beckyheadshot.jpg"
import Brandon from "../images/leadership/brandonheatshot.jpg"
import Donovan from "../images/leadership/donovanheadshot.jpg"
import Ellie from "../images/leadership/ellieheadshot.jpg"
import Henry from "../images/leadership/henryheadshot.jpg"
import Nazar from "../images/leadership/nazarheadshot.jpg"
import Scruggs from "../images/leadership/scruggsheadshot.jpg"
import Soham from "../images/leadership/sohamheadshot.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faInstagram,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"

const Leadership = () => (
  <section className="lead-wrapper">
    <span id="leads"></span>
    <Heading title="LEADERSHIP" size="h1" />
    <div className="exec-grid">
      <div className="box">
        <figure>
          <img src={Rodrigo} alt="Rodrigo Dacosta" />
          <div className="box-title">
            <h3>Rodrigo Dacosta</h3>
            <h6>Chief Executive Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/rodrigo-m-dacosta"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:rmdacost@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/rodrigo.of.the.coast/profilecard/?igsh=MXJrc2F2d2dpeDhkYg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Alex} alt="Alexander Allen Chief Engineer" />
          <div className="box-title">
            <h3>Alexander Allen</h3>
            <h6>Chief Executive Engineer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/alexander-allen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:arallen@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://github.com/robocoder99"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Henry} alt="Henry Stearns CFO" />
          <div className="box-title">
            <h3>Henry Stearns</h3>
            <h6>Chief Financial Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/henry-stearns-b00789245/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:hjstearn@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Becky} alt="Becky Sterinbach Safety Officer" />
          <div className="box-title">
            <h3>Becky Sterinbach</h3>
            <h6>Safety Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/rsterinbach/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:rmsterin@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Ellie} alt="Ellie Murray Safety Officer" />
          <div className="box-title">
            <h3>Ellie Murray</h3>
            <h6>Safety Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/ellie-r-murray/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:ermurra4@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Donovan} alt="Donovan Ngum Propulsion Lead" />
          <div className="box-title">
            <h3>Donovan Ngum</h3>
            <h6>Propulsion Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/donovanngum/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:ddngum@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Soham} alt="Soham Gotkhindikar Structures Lead" />
          <div className="box-title">
            <h3>Soham Gotkhindikar</h3>
            <h6>Structures Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/soham-gotkhindikar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:ssgotkh2@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Brandon} alt="Brandon Troy Programming Lead" />
          <div className="box-title">
            <h3>Brandon Troy</h3>
            <h6>Programming Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/brandontroy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:bjtroy@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Scruggs} alt="Alex Scruggs Avionics Lead" />
          <div className="box-title">
            <h3>Alex Scruggs</h3>
            <h6>Avionics Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/alex-scruggs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:anscrugg@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
	<div className="box">
        <figure>
          <img src={Nazar} alt="Nazar Rush Lead of Propulsion" />
          <div className="box-title">
            <h3>Nazar Rush</h3>
            <h6>Former Propulsion Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
            <a
                href="https://www.linkedin.com/in/nazar-rush/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:nazar@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
    <br />
    <RedButton
      link="https://forms.gle/LC3hbRnmkREGbvnp6"
      text=" Join The Team!"
    />
  </section>
)

export default Leadership
