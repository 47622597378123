import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import RedButton from "../redbutton.js";
import Dropdown from "./Dropdown.js";

const NavItem = styled(AnchorLink)`
    text-decoration: none;
    padding-right: 25px;
    display: inline-block;
    white-space: nowrap;
    margin: 0 1vw;
    transition: all 0.3s ease;
    position: relative;
    color: rgba(255, 250, 250, 1);

    :hover {
        color: rgb(255, 30, 30);
    }
        
    :after {
        content: "";
        position: absolute;
        width: 0;
        height: 1.8px;
        display: block;
        margin-top: 5px;
        left: 0;
        background: rgb(255, 30, 30);
        transition: width 0.3s ease;
        -webkit-transition: width 0.2s ease;
    }
        
    :hover {
        ::after {
            width: 78%;
        }        
    }

    @media (max-width: 768px) {
        padding: 20px 0;
        font-size: 1.5rem;
        z-index: 6;
    }
`;

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const DonateButtonContainer = styled.div`
    margin-left: 30px;  /* Adjust this value to move the Donate button more to the right */
`;

const NavbarLinks = () => {
  return (
    <NavContainer>
      <NavItem a className="link" to="/#hero-id">
        Home
      </NavItem>

      <NavItem a className="link" to="/#about">
        About Us
      </NavItem>

      <NavItem a className="link" to="/#test-stand">
        Pilot Project
      </NavItem>

      <NavItem a className="link" to="/#leads">
        Leadership
      </NavItem>

      <NavItem a className="link" to="#sponsors">
        Sponsors
      </NavItem>

      <NavItem a className="link" to="#contact">
        Contact
      </NavItem>
      <Dropdown />

      <DonateButtonContainer>
        <RedButton
          link="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=GL3SSSK45D4X2&source=url"
          text="Donate"
        />
      </DonateButtonContainer>
    </NavContainer>
  );
};

export default NavbarLinks;
