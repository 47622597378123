import React from "react";
import "../../styles/Dropdown.css";

const Dropdown = () => {
    return (
        <div className="dropdown">
            <nav className="nav nav3">
                <ul>
                    <li>
                        <span className="drop-title">Internal</span>
                        <ul>
                            <div className="backdrop"></div> {/* Backdrop element */}
                            <li><a href="http://wiki.liquidrocketry.com/" rel="noopener noreferrer">Confluence</a></li>
                            <li><a href="http://tracker.liquidrocketry.com/" rel="noopener noreferrer">Jira</a></li>
                            <li><a href="http://repo.liquidrocketry.com/" rel="noopener noreferrer">BitBucket</a></li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Dropdown;
